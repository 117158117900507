<script lang="ts">
    import { Select } from "select.myst";
    import { HighlightSvelte } from "svelte-highlight";
    import theme from "svelte-highlight/src/styles/atlas";

    const data1: [String, String][] = [
        ["vim", "Vim"],
        ["vscode", "Visual Studio Code"],
        ["sublime", "Sublime Text"],
        ["atom", "Atom"],
        ["emacs", "Emacs"],
        ["npp", "Nodepad++"],
        ["gedit", "Gedit"],
        ["joe", "Joe"],
        ["nodepad", "Notepad"],
        ["nano", "Nano"],
        ["micro", "Micro"]
    ];

    let lang: [String, String] = data1[7];

    const code = `<script lang="ts">

    import { Select } from "select.myst";

    const data1: [String, String][] = [
        ["vim", "Vim"],
        ["vscode", "Visual Studio Code"],
        ["sublime", "Sublime Text"],
        ["atom", "Atom"],
        ["emacs", "Emacs"],
        ["npp", "Nodepad++"],
        ["gedit", "Gedit"],
        ["joe", "Joe"],
        ["nodepad", "Notepad"],
        ["nano", "Nano"],
        ["micro", "Micro"]
    ];

    // preselect an option
    let lang: [String, String] = data1[7];

<\/script>

<Select id="text-editor"
        label="what is your favourite text editor?"
        options={data1}
        bind:selectedValue={lang} />
`

</script>

<svelte:head>
    {@html theme}
</svelte:head>

<main>
    <h1>select.myst</h1>

    <p>custom select component for svelte, with filtering and full keyboard control.</p>

    <a href="https://github.com/codemyst/select">github</a><span class="delimiter">-</span>
    <a href="https://www.npmjs.com/package/select.myst">npm</a>

    <hr>

    <Select id="text-editor"
            label="what is your favourite text editor?"
            options={data1}
            bind:selectedValue={lang} />

    <p class="selected">you have selected: [{lang}]</p>

    <HighlightSvelte {code} />

    <hr>

    <p>made by <a href="https://myst.rs/">codemyst</a></p>
</main>

<style>

    h1 {
        font-size: 2.5em;
        margin-top: 1em;
    }

    p {
        margin-top: 1.5em;
        margin-bottom: 1.5em;
    }

    p.selected {
        margin-bottom: 2em;
    }

    hr {
        margin-top: 2em;
        margin-bottom: 2em;
        border-color: var(--color-boulder);
    }

    .delimiter {
        margin-left: 1em;
        margin-right: 1em;
    }

    :global(.select .value) {
        background-color: var(--color-cod-gray-light) !important;
    }

    :global(.container) {
        flex-direction: column;
        margin-bottom: 1em !important;
    }

    :global(.container label) {
        align-self: initial !important;
        margin-bottom: 1em !important;
    }

    :global(.select .dropdown) {
        background-color: var(--color-cod-gray-light) !important;
        border: 1px solid var(--color-main);
        border-top: none;
        box-sizing: border-box !important;
    }

    :global(.select.open .value) {
        border: 1px solid var(--color-main);
        border-bottom: none;
        box-sizing: border-box !important;
    }

    :global(.select input) {
        font-family: var(--fontstack);
        font-size: var(--font-size-normal);
    }

    :global(.hljs) {
        background-color: var(--color-cod-gray-light) !important;
        border-radius: 0.2em;
        padding: 0.5em 1em;
    }

    :global(.hljs code) {
        font-family: var(--fontstack-mono) !important;
        white-space: pre-wrap !important;
    }

</style>
