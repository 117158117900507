<script>var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import { tick } from "svelte";
export let id;
export let label;
export let options;
// provided options filtered by a search string
let filteredOptions = options;
let open = false;
// did the search find any options?
let found = true;
let search = "";
let selectElement;
let searchElement;
let dropdownElement;
export let selectedValue = options[0];
let selectedElement;
// set to true when an option is pressed
let mouseDown = false;
// filters options by a search string
// both the key and value are compared
const filterOptions = () => {
    filteredOptions = options.filter(e => e[0].toLowerCase().indexOf(search.toLowerCase()) > -1 ||
        e[1].toLowerCase().indexOf(search.toLowerCase()) > -1);
    found = filteredOptions.length > 0;
    if (found) {
        selectedValue = filteredOptions[0];
    }
};
// when the value element is pressed toggle the open state
const valueClickHandler = (e) => __awaiter(void 0, void 0, void 0, function* () {
    yield setOpen(!open);
    // prevents the focus element from firing which would just immediately close the select
    e.preventDefault();
});
// when the value element is focused open the select,
// focusing on an element by tabbing should never close it
const valueFocusHandler = () => __awaiter(void 0, void 0, void 0, function* () {
    yield setOpen(true);
});
const setOpen = (o) => __awaiter(void 0, void 0, void 0, function* () {
    open = o;
    // after opening, wait for the DOM to update
    // and reset the search
    if (open) {
        yield tick();
        searchElement.focus();
        search = "";
        filteredOptions = options;
        scrollSelectedIntoView();
    }
});
// when focus is lost on the search bar close the select
const searchBlurHandler = () => {
    // dont trigger if an option is selected
    if (mouseDown)
        return;
    open = false;
};
// key handler for the search bar
const keyDownHandler = (e) => __awaiter(void 0, void 0, void 0, function* () {
    switch (e.key) {
        // close the dropdown
        case "Escape":
        case "Enter":
            {
                open = false;
            }
            break;
        // select the last option
        case "End":
            {
                e.preventDefault();
                selectedValue = filteredOptions[filteredOptions.length - 1];
                yield tick();
                scrollSelectedIntoView();
            }
            break;
        // select the first option
        case "Home":
            {
                e.preventDefault();
                selectedValue = filteredOptions[0];
                yield tick();
                scrollSelectedIntoView();
            }
            break;
        case "ArrowUp":
            {
                e.preventDefault();
                const index = tupleIndexOf(filteredOptions, selectedValue);
                selectedValue = index > 0 ?
                    filteredOptions[index - 1] :
                    filteredOptions[0];
                yield tick();
                scrollSelectedIntoView();
            }
            break;
        case "ArrowDown":
            {
                e.preventDefault();
                const index = tupleIndexOf(filteredOptions, selectedValue);
                selectedValue = index < filteredOptions.length - 1 ?
                    filteredOptions[index + 1] :
                    filteredOptions[filteredOptions.length - 1];
                yield tick();
                scrollSelectedIntoView();
            }
            break;
    }
});
const scrollSelectedIntoView = () => {
    dropdownElement.scrollTop = selectedElement.offsetTop - dropdownElement.offsetTop;
};
const optionMouseDownHandler = (v) => {
    mouseDown = true;
    selectedValue = v;
};
const optionMouseUpHandler = () => {
    mouseDown = false;
    open = false;
};
// finds the index of a tuple in a tuple array
// this is needed because tuples are objects, and .indexOf would compare references
const tupleIndexOf = (a, b) => {
    for (let i = 0; i < a.length; i++) {
        if (tupleEquals(a[i], b))
            return i;
    }
};
// compares 2 tuples by the contents instead of reference
const tupleEquals = (a, b) => {
    return a[0] === b[0] && a[1] === b[1];
};
</script>

<style>

    * {
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }

    .container {
        display: flex;
    }

    label {
        display: inline-block;
        margin-right: 0.5em;
        align-self: center;
    }

    .select {
        display: inline-block;
        position: relative;
        color: #ffffff;
        width: 200px;
    }

    .select .value {
        cursor: pointer;
        padding: 0.5em 1em;
        padding-right: 2em;
        background-color: #141414;
        outline: none;
        border-radius: 0.2em;
        display: block;
        user-select: none;
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
    }

    .select .value::after {
        content: "\00203A";
        position: absolute;
        right: 12px;
        top: calc(50%);
        transform: translateY(-50%) rotate(90deg);
        pointer-events: none;
    }

    .select.open .value::after {
        content: "\002039";
    }

    .select.open .value {
        border-radius: 0.2em 0.2em 0 0;
    }

    .select.open .dropdown {
        display: flex;
    }

    .dropdown {
        position: absolute;
        display: none;
        flex-direction: column;
        width: 100%;
        background: #141414;
        z-index: 999;
        max-height: 275px;
        overflow-y: auto;
        border-radius: 0 0 0.2em 0.2em;
    }

    .not-found {
        padding: 0.5em 1em;
    }

    .select input {
        background-color: #1c1c1c;
        color: #ffffff;
        border: none;
        padding: 0.5em 1em;
        position: sticky;
        top: 0;
        outline: none;
    }

    .option {
        cursor: pointer;
        padding: 0.5em 1em;
        cursor: pointer;
    }

    .option:hover {
        color: orange;
        background-color: #1c1c1c;
    }

    .option.selected {
        background-color: orange;
        color: #1c1c1c;
    }

</style>

<div id={id} class="container">

    <label id="{id}-label"
           for="{id}-select"
           class="select-label">
        {label}
    </label>

    <div id="{id}-select"
         class="select"
         aria-labelledby="{id}-label"
         bind:this={selectElement}
         class:open>

        <span class="value"
              tabindex="0"
              aria-label="{selectedValue[1]}, listbox {tupleIndexOf(options, selectedValue)} of {options.length}"
              aria-live="polite"
              role="combobox"
              aria-labelledby="{id}-label"
              aria-haspopup="listbox"
              aria-expanded={open}
              on:mousedown={valueClickHandler}
              on:focus={valueFocusHandler}>
            {selectedValue[1]}
        </span>

        <div class="dropdown"
             role="listbox"
             bind:this={dropdownElement}>

            <input type="text"
                   autocomplete="off"
                   placeholder="Filter..."
                   bind:this={searchElement}
                   bind:value={search}
                   on:input={filterOptions}
                   on:keydown={keyDownHandler}
                   on:blur={searchBlurHandler}>

            {#if !found}
                <p class="not-found">No items found</p>
            {/if}

            {#each filteredOptions as [key, value]}

                {#if tupleEquals(selectedValue, [key, value])}

                    <div class="option selected"
                         aria-selected="true"
                         role="option"
                         on:mousedown={() => optionMouseDownHandler([key, value])}
                         on:mouseup={optionMouseUpHandler}
                         bind:this={selectedElement}>
                        {value}
                    </div>

                {:else}

                    <div class="option"
                         aria-selected="false"
                         role="option"
                         on:mousedown={() => optionMouseDownHandler([key, value])}
                         on:mouseup={optionMouseUpHandler}>
                        {value}
                    </div>

                {/if}


            {/each}

        </div>

    </div>

</div>

